import React from 'react';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import { trackScoreArt } from '../../shared/section-items/track-score';
import marble_app from '../../../../images/marble_app.svg';

export default function KissDebtGoodbye() {
  const data: FeatureSectionProps = {
    title: 'Kiss debt goodbye.',
    fullLengthTitle: true,
    items: [
      {
        align: 'right',
        title: 'Free credit tools powered by MyMarble',
        subtitle: 'Boost your credit score',
        description: `Set goals and jump-start your credit score, lower your debt and create cash flow with Nuborrow and MyMarble.`,
        actions: [
          { label: `Apply Today For Free`, href: 'https://apply.nuborrow.com', isPrimary: true }
        ],
        art: trackScoreArt('left', marble_app)
      }
    ]
  };

  return <FeatureSection {...data} />;
}
